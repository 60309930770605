define("discourse/plugins/tai-locale/discourse/initializers/tai-initializer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'tai_locale',
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.tai_locale_disable_others) return;
      (0, _pluginApi.withPluginApi)('0.11.1', api => {
        api.modifyClass('controller:preferences/interface', dt7948.p({
          availableLocales() {
            return [{
              value: 'en',
              name: 'English'
            }, {
              value: 'tai_Han',
              name: '台語（漢字）'
            }, {
              value: 'tai_Rom',
              name: 'Tâi-gí (Lô-jī)'
            }];
          }
        }, [["method", "availableLocales", [(0, _decorators.default)()]]]));
      });
    }
  };
});